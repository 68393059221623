<template>
  <NotPermission v-if="!authenticated" />

  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs :routes="[{ name: 'Inicio' }, { name: 'Etapas' }, { name: 'Listado' }]" />
    <v-card>
      <v-toolbar color="grey darken-1" dark card>
        <v-toolbar-title>Listado de Descuentos</v-toolbar-title>
        <v-spacer />
        <v-btn :to="{ name: 'crearDescuento' }" color="primary">
        <svg style="width:24px;height:24px; margin-right:10px;" viewBox="0 0 24 24">
              <path fill="currentColor" d="M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z" />
          </svg>
          Agregar Descuentos
        </v-btn>
      </v-toolbar>
      <v-container fluid grid-list-lg>
        <v-layout row wrap>
          <v-flex sm6> </v-flex>
          <v-flex sm6>
            <v-text-field v-model="search" box label="Buscar" clearable hide-details />
          </v-flex>
          <v-flex xs12>
            <v-data-table
              :headers="[
                { text: 'SKU-ITEM', value: 'itemProducto.SKU ' },
                { text: 'Producto', value: 'itemProducto.producto.nombre' },
                { text: 'Item', value: 'itemProducto.nombre' },
                { text: '% Descuento', value: 'porcentaje' },
                { text: 'Fecha Inicio', value: 'fecha_inicio' },
                { text: 'Fecha Término', value: 'fecha_termino' },
                { text: 'Categoría', value: 'itemProducto.categoria.nombre' },
                { text: 'Estado', value: 'estado' },
                { text: 'Acciones' }
              ]"
              :items="descuentos"
              :loading="loadingDescuentos"
              :search="search"
              :rows-per-page-items="[10, 25, 35, 50]"
              class="elevation-1"
            >
              <tr slot="items" slot-scope="props">
                <td class="px-3">
                  {{ props.item.itemProducto.SKU }}
                </td>
                <td class="px-3">
                  {{ props.item.itemProducto.producto.nombre }}
                </td>
                <td class="px-3">
                  {{ props.item.itemProducto.nombre }}
                </td>
                <td class="px-3 text-xs-center">{{ props.item.porcentaje }}%</td>
                <td class="px-3">
                  {{ formatDate(props.item.fecha_inicio) }}
                </td>
                <td class="px-3">
                  {{ formatDate(props.item.fecha_termino) }}
                </td>
                <td class="px-3">
                  {{ props.item.itemProducto.categoria.nombre }}
                </td>
                <td class="px-3">
                  <v-chip v-if="props.item.estado === 1" small color="primary" text-color="white">
                    Activo
                  </v-chip>
                  <v-chip v-else-if="props.item.estado === 0" small>
                    Inactivo
                  </v-chip>
                </td>
                <td class="text-xs-center px-3">
                  <v-btn
                    class="ma-0"
                    :to="{ name: 'editDescuento', params: { id: props.item.id } }"
                    small
                    icon
                    flat
                    color="info"
                  >
                    <v-icon small>
                      edit
                    </v-icon>
                  </v-btn>
                  <v-btn
                    class="ma-0"
                    small
                    @click="openModalDeleteDescuento(props.item)"
                    flat
                    icon
                    color="error"
                  >
                    <v-icon small>
                      delete
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
    <ModalDelete />
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  metaInfo() {
    return { title: "Listado de Descuentos" };
  },

  components: {
    NotPermission: () => import("@/views/errors/NotPermission"),
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    ModalDelete: () => import("@/views/discount/ModalDelete")
  },

  data() {
    return {
      search: ""
    };
  },

  computed: {
    ...mapState({
      descuentos: state => state.descuentos.descuentos,
      loadingDescuentos: state => state.descuentos.loadingDescuentos
    }),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user"
    })
  },
  created() {
    this.getDescuentos();
  },

  methods: {
    ...mapActions({
      getDescuentos: "descuentos/getDescuentos",
      replaceShowModalDeleteDescuento: "descuentos/replaceShowModalDeleteDescuento",
      replaceCurrentDescuento: "descuentos/replaceCurrentDescuento"
    }),
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    openModalDeleteDescuento(descuento) {
      this.replaceCurrentDescuento({ descuento });
      this.replaceShowModalDeleteDescuento({ status: true });
    }
  }
};
</script>
